.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    border-right: 1px dashed #919eab3d !important;
    background-color: #f9fafbb8 !important;
}


/* sidebr */
.user {
    cursor: pointer;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-radius: 12px;
    background-color: rgba(145, 158, 171, 0.12);
}

.user h6 {
    margin: 0px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    color: rgb(33, 43, 54);
}

.sidebar-list {
    gap: 10px;
    border: 0px;
    padding: 8px 8px 8px 15px;
    cursor: pointer;
    vertical-align: middle;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 0.875rem;
    height: 48px;
    text-transform: capitalize;
    border-radius: 8px;
    color: rgb(99, 115, 129) !important;
}

.sidebar-list.active {
    border-left: 3px solid #1565c0;
    color: #1565c0 !important;
    background-color: #d7e4f4;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.sidebar-list:hover , .accountpopover-link:hover{
    text-decoration: none;
    background-color: rgba(145, 158, 171, 0.08);
    border-radius: 8px;
}

/* topbar */
.css-1navlpy-MuiPaper-root-MuiAppBar-root {
    background-color: rgb(249 250 251 / 99%) !important;
    box-shadow: none !important;
    color: #637381 !important;
}

.accountpopover-link {
    color: #637381 !important;
}